.company-registration-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: auto;
}

.ant-divider {
    margin: 16px 0;
}

.ant-row {
    margin-bottom: 12px;
}

.ant-form-item {
    margin-bottom: 12px;
}
