/* src/features/auth/Login.css */

.login-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.logo-title-wrapper {
  text-align: center;
  margin-bottom: 8px;
  margin-top: 20px; /* Add top margin before logo */
}

.logo {
  width: 120px; /* Increased logo size */
  height: auto;
  margin-bottom: 8px; /* Space between logo and title */
}

.app-title {
  color: #033D72FF;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
}

.app-subtitle {
  font-size: 16px;
  color: #888;
  margin: 4px 0 4px 0; /* Reduced bottom margin */
}

.login-form-wrapper {
  margin-top: -20px; /* Adjusted negative margin for tighter spacing */
}
